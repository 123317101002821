<template lang="pug">
	.menu-icon(:class="extraClass")
		.menu-icon-line
		.menu-icon-line
		.menu-icon-line
</template>

<style lang="scss" scoped>
.menu-icon {
    padding: 0 3px;
    width: 30px;

    .menu-icon-line {
        border-bottom: 2px solid var(--theme-color-1);
        margin-bottom: 7px;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }
    &.redDot {
        position: relative;
        &:after {
            content: '';
            display: block;
            background: var(--red-text-color);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 3px solid var(--wr-text-color);

            position: absolute;
            right: -3px;
            top: -5px;
        }
    }
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            me: 'auth/me',
        }),
        extraClass() {
            let res = '';
            if (this.me?.isMerchantPartner && this.me?.hasNotifications) {
                res += ' redDot';
            }
            return res;
        },
    },
};
</script>
